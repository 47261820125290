<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    @click:outside="close"
    :persistent="loading"
    scrollable
  >
    <v-card>
      <v-card-title>{{ client.id ? "Editar" : "Novo" }} cliente</v-card-title>
      <v-card-text>
        <v-form v-model="valid" class="mt-1">
          <v-text-field
            v-model="client.name"
            :rules="[(v) => !!v || 'Nome é obrigatório']"
            label="Nome"
            required
            outlined
            dense
            :disabled="loading"
          />
          <v-text-field
            v-model="client.document"
            :rules="[(v) => !!v || 'Documento é obrigatório']"
            label="Documento"
            required
            :disabled="loading"
            placeholder="CPF ou RG"
            outlined
            dense
          />

          <v-select
            :items="blocks"
            v-model="client.blockId"
            label="Bloco"
            dense
            outlined
            :rules="[(v) => !!v || 'Bloco é obrigatório']"
          ></v-select>

          <v-select
            v-if="client.blockId !== 'employee'"
            v-model="client.apartment"
            :disabled="!client.blockId"
            :items="availableApartments"
            label="Apartamento"
            dense
            outlined
            :rules="[(v) => !!v || 'Apto é obrigatório']"
          />

          <v-text-field-simplemask
            v-model="client.phone"
            label="Whatsapp"
            :properties="{
              prefix: '',
              suffix: '',
              rules: [
                (v) => !!v || 'Whatsapp é obrigatório',
                (v) => (v && v.length === 15) || 'Whatsapp inválido',
              ],
              disabled: loading,
              outlined: true,
              dense: true,
              placeholder: '',
            }"
            v-bind:options="{
              inputMask: '(##) #####-####',
              outputMask: '###########',
              empty: null,
              applyAfter: false,
              alphanumeric: true,
              lowerCase: false,
            }"
          />

          <v-text-field
            v-model="client.instagram"
            label="Instagram"
            :disabled="loading"
            outlined
            dense
          />

          <v-text-field
            v-model="client.email"
            label="Email"
            :rules="[(v) => !v || /.+@.+/.test(v) || 'Email inválido']"
            :disabled="loading"
            outlined
            dense
          />

          <v-text-field
            v-model="client.accessId"
            label="ID de acesso"
            :disabled="loading"
            placeholder="Deixe em branco caso não cadastrado"
            outlined
            dense
            type="tel"
            hide-details
          />

          <v-alert v-if="error" type="error" border="left">
            {{ error }}
          </v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-switch
          v-if="client.id"
          v-model="client.active"
          label="Ativo"
          :disabled="loading"
          dense
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn @click="close" text :disabled="loading"> Cancelar </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          @click="save"
          :disabled="!valid"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import STORE from "@/services/store";

const defaultObject = () => ({
  name: "",
  document: "",
  phone: "",
  instagram: null,
  email: null,
  accessId: null,
  blockId: null,
  apartment: null,
  active: true,
});

export default {
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    error: false,
    client: defaultObject(),
  }),
  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;

        if (!this.client.id)
          await STORE.client.create(this.$route.params.id, this.client);
        else
          await STORE.client.update(
            this.$route.params.id,
            this.client.id,
            this.client
          );

        this.loading = false;
        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message || "Erro desconhecido";
        this.loading = false;
      }
    },
    open(client) {
      this.client = Object.assign(defaultObject(), client);
      if (client && client.blockId === null) this.client.blockId = "employee";
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.error = false;
    },
  },

  computed: {
    ...mapGetters("store", ["storeById"]),
    availableApartments() {
      const block = this.blocks.find((b) => b.id === this.client.blockId);
      if (!block) return [];

      return block.apartments
        .map((apartments, floor) => {
          debugger;
          var resp = [];
          for (let number = 1; number <= apartments; number++) {
            resp.push(
              this.$options.filters.apartmentFormat(
                {
                  floor: floor + 1,
                  number,
                },
                block.apartmentFormat
              )
            );
          }
          return resp;
        })
        .flat();
    },

    blocks() {
      const store = this.storeById(this.$route.params.id);
      return [
        ...store.Condominium.Blocks.map((b) => ({
          ...b,
          text: b.name,
          value: b.id,
        })),
        {
          text: "Funcionário",
          value: "employee",
        },
      ];
    },
  },
};
</script>

<style></style>
