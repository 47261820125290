<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    @click:outside="close"
    :persistent="loading"
    scrollable
  >
    <v-card>
      <v-card-title>Código de Acesso</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-card outlined class="d-flex pa-4 gap-2 align-center mb-4">
            <v-avatar size="48" color="primary">
              <v-icon color="white" large>mdi-account</v-icon>
            </v-avatar>
            <div>
              <h4 class="mb-0">{{ client.name }}</h4>
              <a :href="`//wa.me/55${client.phone}`" target="_blank">
                <v-icon color="primary" small>mdi-phone</v-icon>
                {{ client.phone || "" | phone }}
              </a>
              <div class="d-flex gap-1">
                <v-icon small>mdi-office-building</v-icon>
                <p class="mb-0">
                  {{ blocks[client.blockId] }} - {{ client.apartment }}
                </p>
              </div>
            </div>
          </v-card>

          <v-text-field
            v-model="client.accessId"
            label="Código da portaria"
            :disabled="loading"
            placeholder="Deixe em branco caso não cadastrado"
            outlined
            autofocus
            type="tel"
          />

          <v-alert v-if="error" type="error" border="left">
            {{ error }}
          </v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-switch
          v-if="client.id"
          v-model="client.active"
          label="Ativo"
          :disabled="loading"
          dense
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn @click="close" text :disabled="loading"> Cancelar </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          @click="save"
          :disabled="!valid"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import STORE from "@/services/store";

const defaultObject = () => ({
  name: "",
  document: "",
  accessId: null,
  active: true,
});

export default {
  name: "AccessIdModal",
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    error: false,
    client: defaultObject(),
  }),
  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;

        await STORE.client.update(this.$route.params.id, this.client.id, {
          accessId: this.client.accessId,
          active: this.client.active,
        });

        this.loading = false;
        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message || "Erro desconhecido";
        this.loading = false;
      }
    },
    open(client) {
      this.client = Object.assign(defaultObject(), client);
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.error = false;
    },
  },

  computed: {
    ...mapGetters("store", ["storeById"]),
    store() {
      return this.storeById(this.$route.params.id);
    },
    blocks() {
      return Object.fromEntries(
        this.store.Condominium.Blocks.map((b) => [b.id, b.name])
      );
    },
  },
};
</script>

<style></style>
