<template>
  <v-dialog v-model="dialog" :persistent="loading" fullscreen scrollable>
    <v-card>
      <v-card-title>
        <div class="d-flex gap-4 align-center w-100">
          <div>
            <v-img :src="store.Brand?.logo" height="50" width="50" contain />
          </div>
          <div class="d-flex flex-column">
            <span class="text-overline" style="line-height: 1rem">
              {{ store.Brand?.name }}
            </span>
            <h5 class="mb-0">{{ store.Condominium.name }}</h5>
          </div>
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab v-for="item in filteredTabs" :key="item.id">
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-tab>

          <v-tab-item
            v-for="(item, i) in filteredTabs"
            :key="item.id"
            :value="i"
          >
            <component :is="item.component" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import Members from "./settingsComponent/Members.vue";

export default {
  components: {
    Members,
  },
  data: () => ({
    dialog: false,
    tab: null,
    loading: false,
    tabs: [
      {
        id: "members",
        permission: 64,
        icon: "mdi-account-group",
        title: "Colaboradores",
        component: "Members",
      },
    ],
  }),
  methods: {
    open(client) {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },

  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("store", ["storeById"]),
    store() {
      return this.storeById(this.$route.params.id);
    },
    filteredTabs() {
      return this.tabs.filter((tab) => this.hasPermission(tab.permission));
    },
  },
};
</script>

<style></style>
