<template>
  <div>
    <v-card
      class="pa-4 d-flex align-center justify-space-around flex-wrap mb-4 mt-2"
      rounded="lg"
    >
      <div class="text-center">
        <small>
          {{ aggregate ? aggregate.length : null }} de
          {{ metrics?.apartmentsCount }}
        </small>
        <h3>{{ metrics?.penetration }}%</h3>
        <span> Penetração </span>
      </div>
      <div class="text-center">
        <h3>{{ metrics?.clientsCount }}</h3>
        <span> Clientes </span>
      </div>
      <div class="text-center">
        <h3>{{ metrics?.clientsPerApartment }}</h3>
        <span> Clientes p/ apto </span>
      </div>
      <div class="text-center warning--text">
        <h3>{{ metrics?.countPending }}</h3>
        <span> Cadastro pendente </span>
      </div>
    </v-card>
    <div class="d-flex justify-space-between mb-4">
      <v-btn-toggle v-model="viewType">
        <v-btn value="ClientList" small>
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
        <v-btn value="BlockView" small>
          <v-icon>mdi-view-grid</v-icon>
        </v-btn>
      </v-btn-toggle>
      <div>
        <v-btn
          @click="getClients"
          icon
          small
          :disabled="!clients || disableReload"
          :loading="loading"
          class="mr-2"
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>

        <v-btn
          @click="addClient"
          color="primary"
          rounded
          small
          :disabled="!clients"
        >
          <v-icon small left>mdi-plus</v-icon>
          Cliente
        </v-btn>
      </div>
    </div>
    <v-card :loading="loading && !clients">
      <div class="pa-2">
        <v-alert v-if="!!error" type="error" border="left" class="mb-0">
          {{ error }}
        </v-alert>
        <component
          :is="viewType"
          v-if="clients"
          :clients="clients"
          :blocks="blocks"
          :aggregate="aggregate"
          :loading="loading"
          @edit="editClient"
          @access-id="$refs['access-id-modal'].open($event)"
        />
      </div>
    </v-card>

    <AccessIdModal ref="access-id-modal" @success="getClients" />
    <UserModal ref="user-modal" @success="getClients" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserModal from "./modal/UserModal.vue";
import AccessIdModal from "./modal/AccessIdModal.vue";
import STORE from "@/services/store";
import ClientList from "./ClientList.vue";
import BlockView from "./BlockView.vue";

export default {
  name: "ClientManagement",
  data: () => ({
    clients: null,
    aggregate: null,
    loading: false,
    error: false,
    viewType: "ClientList",
    disableReload: false,
  }),
  methods: {
    addClient() {
      this.$refs["user-modal"].open();
    },
    editClient(client) {
      this.$refs["user-modal"].open(client);
    },
    async getClients() {
      try {
        this.loading = true;
        this.error = false;

        const { clients, aggregate } = await STORE.client.list(
          this.$route.params.id
        );

        this.clients = clients;
        this.aggregate = aggregate;
        this.disableReload = true;
        setTimeout(() => (this.disableReload = false), 1000 * 5);
      } catch (e) {
        this.error = e.message || "Erro desconhecido";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("store", ["storeById"]),
    store() {
      return this.storeById(this.$route.params.id);
    },
    metrics() {
      const { clients, aggregate } = this;
      if (!clients || !aggregate) return null;
      const countBlock = this.store.Condominium.Blocks.reduce((acc, block) => {
        return acc + block.apartments.reduce((acc, apart) => acc + apart, 0);
      }, 0);

      const activeClients = clients.filter((c) => c.active);
      const clientsWithApartment = activeClients.filter((c) => c.blockId);
      return {
        penetration: Math.round((aggregate.length / countBlock) * 100),
        apartmentsCount: countBlock,

        clientsCount: activeClients.length,
        clientsPerApartment: Math.round(
          clientsWithApartment.length / (aggregate.length || 1)
        ).toFixed(1),
        countPending: activeClients.filter((c) => !c.accessId).length,
      };
    },
    blocks() {
      return Object.fromEntries(
        this.store.Condominium.Blocks.map((b) => [b.id, b.name])
      );
    },
  },
  components: { UserModal, AccessIdModal, ClientList, BlockView },
  mounted() {
    this.getClients();
  },
};
</script>
