var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500","persistent":_vm.loading,"scrollable":""},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.user.id ? "Editar" : "Novo")+" colaborador ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":[(v) => !!v || 'Nome é obrigatório'],"label":"Nome","required":"","outlined":"","dense":"","disabled":_vm.loading},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('v-text-field',{attrs:{"rules":[(v) => !!v || 'Email é obrigatório'],"label":"Email","type":"email","required":"","outlined":"","dense":"","disabled":_vm.loading},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-text-field-simplemask',{attrs:{"label":"Whatsapp","properties":{
            prefix: '',
            suffix: '',
            rules: [
              (v) => !!v || 'Whatsapp é obrigatório',
              (v) => v?.length === 15 || 'Whatsapp inválido',
            ],
            disabled: _vm.loading,
            outlined: true,
            dense: true,
            placeholder: '',
          },"options":{
            inputMask: '(##) #####-####',
            outputMask: '###########',
            empty: null,
            applyAfter: false,
            alphanumeric: true,
            lowerCase: false,
          }},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('v-divider',{staticClass:"my-2"}),_c('b',[_vm._v("Permissões")]),(!_vm.isOwner)?_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.permissionList),function([value, label]){return _c('v-col',{key:value,attrs:{"cols":"12","sm":"6"}},[_c('v-checkbox',{attrs:{"label":label,"value":value,"disabled":_vm.loading,"dense":"","hide-details":""},model:{value:(_vm.user.permissions),callback:function ($$v) {_vm.$set(_vm.user, "permissions", $$v)},expression:"user.permissions"}})],1)}),1):_c('p',[_c('b',[_vm._v("Proprietário")])]),(_vm.error)?_c('v-alert',{attrs:{"type":"error","border":"left"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"pb-4"},[(_vm.user.id)?_c('v-switch',{attrs:{"label":"Ativo","disabled":_vm.loading,"dense":""},model:{value:(_vm.user.active),callback:function ($$v) {_vm.$set(_vm.user, "active", $$v)},expression:"user.active"}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }