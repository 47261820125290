<template>
  <div>
    <div class="d-flex mb-2 gap-4 pa-2">
      <div class="d-flex align-center gap-2">
        <div class="apartment-label grey" />
        Sem Cadastro
      </div>
      <div class="d-flex align-center gap-2">
        <div class="apartment-label green" />
        Com Cadastro
      </div>
    </div>
    <div class="d-flex flex-wrap align-end gap-y-3">
      <div v-for="block in blocks" :key="block.id">
        <v-card
          class="d-flex flex-column align-center pa-2 gap-1 mx-2 rounded-b-0 elevation-0"
          outlined
        >
          <h5>
            {{ block.name }}
            <small>
              ({{
                (Object.values(block.clients).length / block.apartmentsLength)
                  | percent
              }})
            </small>
          </h5>

          <div class="d-flex flex-column-reverse gap-1">
            <div
              class="d-flex gap-1"
              v-for="(apartments, floor) in block.apartments"
              :key="floor"
            >
              <v-card
                @click="apartmentClick({ block, floor: floor + 1, number: ap })"
                elevation="0"
                class="apartment flex-grow-1"
                :class="{
                  green:
                    !!block.clients[
                      $options.filters.apartmentFormat(
                        { floor: floor + 1, number: ap },
                        block.apartmentFormat
                      )
                    ],
                  grey: !block.clients[
                    $options.filters.apartmentFormat(
                      { floor: floor + 1, number: ap },
                      block.apartmentFormat
                    )
                  ],
                }"
                v-for="ap in apartments"
                :key="`apartment-${floor}-${ap}`"
              >
                {{
                  { floor: floor + 1, number: ap }
                    | apartmentFormat(block.apartmentFormat)
                }}
              </v-card>
            </div>
          </div>
        </v-card>
        <div class="grass"></div>
      </div>
        <!-- <div>
          <v-card
            class="d-flex flex-column align-center pa-2 gap-1 mx-2 rounded-b-0 elevation-0"
            outlined
          >
            <h5>
              Funcionários
            </h5>
            <div class="d-flex flex-column-reverse gap-1">
              <div
                class="d-flex gap-1"
              >
                <v-card
                  @click="apartmentClick({ block, floor: floor + 1, number: ap })"
                  elevation="0"
                  class=" flex-grow-1"
                  :class="{grey: true}"
                >

                </v-card>
              </div>
            </div>
          </v-card>
          <div class="grass"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BlockView",
  emits: ["edit", "access-id"],
  props: {
    clients: Array,
    loading: Boolean,
    aggregate: Array,
  },
  data: () => ({}),
  methods: {
    apartmentClick({ block, floor, number }) {
      const apartment = this.$options.filters.apartmentFormat(
        { floor, number },
        block.apartmentFormat
      );
      this.$emit("edit", { blockId: block.id, apartment });
    },
  },
  computed: {
    ...mapGetters("store", ["storeById"]),

    blocks() {
      const store = this.storeById(this.$route.params.id);
      return [
        ...store.Condominium.Blocks,
        // { id: null, name: "Funcionários", apartments: [1] },
      ].map((b) => ({
        ...b,
        text: b.name,
        value: b.id,
        apartmentsLength: b.apartments.reduce((acc, a) => acc + a, 0),
        clients: Object.fromEntries(
          this.aggregate
            .filter((c) => c.blockId === b.id)
            .map(({ apartment, _count }) => [apartment, _count])
        ),
      }));
    },
  },
};
</script>

<style scoped>
.apartment-label {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  border-radius: 2px;
}
.apartment {
  min-width: 20px;
  height: 22px;
  border: 1px solid black;
  border-radius: 2px;
  text-align: center;
  padding: 1px;
}

.grass {
  width: 100%;
  height: 10px;
  background: green;
}
</style>
