<template>
  <v-data-table
    :headers="headers"
    :items="clientsMap || []"
    :search="search"
    dense
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisar"
        single-line
        hide-details
        outlined
        dense
      />
    </template>
    <template v-slot:no-data> Nenhum cliente cadastrado </template>
    <template v-slot:item.active="{ item }">
      <div class="d-flex justify-end" small>
        <v-chip :color="!item.active ? 'gray' : 'success'">
          <v-icon v-if="!item.active"> mdi-account-off </v-icon>
          <v-icon v-else-if="!item.accessId"> mdi-account-question </v-icon>
          <v-icon v-else> mdi-account-check </v-icon>
        </v-chip>
      </div>
    </template>

    <template v-slot:item.name="{ item }">
      <div class="d-flex flex-column">
        <small class="font-weight-bold">{{ item.name }}</small>
        <a style="line-height: 1rem" :href="`tel:${item.phone}`">
          <small>
            {{ item.phone | phone }}
          </small>
        </a>
      </div>
    </template>
    <template v-slot:item.blockName="{ item }">
      <div class="d-flex flex-column align-center">
        <small class="font-weight-bold" v-if="!item.blockId">
          Funcionário
        </small>
        <template v-else>
          <small class="font-weight-bold">{{ blocks[item.blockId] }}</small>
          <small>{{ item.apartment }}</small>
        </template>
      </div>
    </template>
    <template v-slot:item.accessId="{ item }">
      <v-chip
        :disabled="loading"
        @click="$emit('access-id', item)"
        :color="!item.active ? 'gray' : item.accessId ? 'success' : 'warning'"
        x-small
        label
      >
        {{ item.accessId ? `#${item.accessId}` : "Pendente" }}
      </v-chip>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn :disabled="loading" icon @click="$emit('edit', item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <!-- <v-btn icon @click="deleteClient(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "ClientList",
  emits: ["edit", "access-id"],
  props: {
    clients: Array,
    loading: Boolean,
    blocks: Object,
  },
  computed: {
    clientsMap() {
      return this.clients.map((client) => ({
        ...client,
        blockName: `${this.blocks[client.blockId] || "Funcionário"} ${
          client.apartment
        }`,
      }));
    },
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "",
        value: "active",
        width: 25,
        cellClass: "px-0 ",
      },
      { text: "Nome", value: "name" },
      {
        text: "Bloco / Apto",
        value: "blockName",
        align: "center",
        sortable: false,
      },
      { text: "ID", value: "accessId", align: "center" },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
  }),
};
</script>
