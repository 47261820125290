<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    @click:outside="close"
    :persistent="loading"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ user.id ? "Editar" : "Novo" }} colaborador
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field
            v-model="user.name"
            :rules="[(v) => !!v || 'Nome é obrigatório']"
            label="Nome"
            required
            outlined
            dense
            :disabled="loading"
          />
          <v-text-field
            v-model="user.email"
            :rules="[(v) => !!v || 'Email é obrigatório']"
            label="Email"
            type="email"
            required
            outlined
            dense
            :disabled="loading"
          />

          <v-text-field-simplemask
            v-model="user.phone"
            label="Whatsapp"
            :properties="{
              prefix: '',
              suffix: '',
              rules: [
                (v) => !!v || 'Whatsapp é obrigatório',
                (v) => v?.length === 15 || 'Whatsapp inválido',
              ],
              disabled: loading,
              outlined: true,
              dense: true,
              placeholder: '',
            }"
            v-bind:options="{
              inputMask: '(##) #####-####',
              outputMask: '###########',
              empty: null,
              applyAfter: false,
              alphanumeric: true,
              lowerCase: false,
            }"
          />

          <v-divider class="my-2" />

          <b>Permissões</b>
          <v-row dense v-if="!isOwner">
            <v-col
              v-for="[value, label] in permissionList"
              :key="value"
              cols="12"
              sm="6"
            >
              <v-checkbox
                v-model="user.permissions"
                :label="label"
                :value="value"
                :disabled="loading"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <p v-else>
            <b>Proprietário</b>
          </p>

          <v-alert v-if="error" type="error" border="left">
            {{ error }}
          </v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-switch
          v-if="user.id"
          v-model="user.active"
          label="Ativo"
          :disabled="loading"
          dense
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn @click="close" text :disabled="loading"> Cancelar </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          @click="save"
          :disabled="!valid"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import STORE from "@/services/store";
import AUTH from "@/services/auth";

const defaultObject = () => ({
  name: "",
  email: "",
  phone: "",
  active: true,
  permissions: [],
});

export default {
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    error: false,
    user: defaultObject(),
    isOwner: false,
    permissionList: [],
  }),
  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;

        const data = {
          ...this.user,
          permissions: this.user.permissions.reduce(
            (acc, p) => acc + parseInt(p),
            0
          ),
        };

        if (!this.user.id) await STORE.user.create(this.$route.params.id, data);
        else await STORE.user.update(this.$route.params.id, this.user.id, data);

        this.loading = false;
        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message || "Erro desconhecido";
        this.loading = false;
      }
    },
    open(user) {
      this.user = Object.assign(defaultObject(), user);
      const [UserStore] = user?.UserStore || [];

      this.isOwner = UserStore?.owner;
      this.user.permissions = (UserStore?.permissions || []).map((p) =>
        p.toString()
      );

      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.error = false;
    },
    async getPermissions() {
      try {
        this.loading = true;
        this.error = false;

        const { permissions } = await AUTH.permissions();
        this.permissionList = Object.entries(permissions);

        this.loading = false;
      } catch (e) {
        this.error = e.message || "Erro desconhecido";
        this.loading = false;
      }
    },
  },

  computed: {
    ...mapGetters("store", ["storeById"]),
    blocks() {
      const store = this.storeById(this.$route.params.id);
      return store.Condominium.Blocks.map((b) => ({
        text: b.name,
        value: b.id,
      }));
    },
  },
  mounted() {
    this.getPermissions();
  },
};
</script>

<style></style>
