var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"d-flex flex-wrap align-end gap-y-3"},_vm._l((_vm.blocks),function(block){return _c('div',{key:block.id},[_c('v-card',{staticClass:"d-flex flex-column align-center pa-2 gap-1 mx-2 rounded-b-0 elevation-0",attrs:{"outlined":""}},[_c('h5',[_vm._v(" "+_vm._s(block.name)+" "),_c('small',[_vm._v(" ("+_vm._s(_vm._f("percent")((Object.values(block.clients).length / block.apartmentsLength)))+") ")])]),_c('div',{staticClass:"d-flex flex-column-reverse gap-1"},_vm._l((block.apartments),function(apartments,floor){return _c('div',{key:floor,staticClass:"d-flex gap-1"},_vm._l((apartments),function(ap){return _c('v-card',{key:`apartment-${floor}-${ap}`,staticClass:"apartment flex-grow-1",class:{
                green:
                  !!block.clients[
                    _vm.$options.filters.apartmentFormat(
                      { floor: floor + 1, number: ap },
                      block.apartmentFormat
                    )
                  ],
                grey: !block.clients[
                  _vm.$options.filters.apartmentFormat(
                    { floor: floor + 1, number: ap },
                    block.apartmentFormat
                  )
                ],
              },attrs:{"elevation":"0"},on:{"click":function($event){return _vm.apartmentClick({ block, floor: floor + 1, number: ap })}}},[_vm._v(" "+_vm._s(_vm._f("apartmentFormat")({ floor: floor + 1, number: ap },block.apartmentFormat))+" ")])}),1)}),0)]),_c('div',{staticClass:"grass"})],1)}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 gap-4 pa-2"},[_c('div',{staticClass:"d-flex align-center gap-2"},[_c('div',{staticClass:"apartment-label grey"}),_vm._v(" Sem Cadastro ")]),_c('div',{staticClass:"d-flex align-center gap-2"},[_c('div',{staticClass:"apartment-label green"}),_vm._v(" Com Cadastro ")])])
}]

export { render, staticRenderFns }