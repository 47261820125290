<template>
  <div>
    <div class="d-flex gap-2 mt-4">
      <v-text-field
        v-model="search"
        label="Pesquisar"
        outlined
        dense
        hide-details
      />
      <v-btn color="primary" @click="addMember">Adicionar</v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="members"
      :loading="loading"
      :search="search"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:item.permission="{ item }">
        <v-chip small class="mr-1" v-if="item.UserStore[0]?.isOwner">
          Proprietário
        </v-chip>
        <v-chip small v-else>
          {{ item.UserStore[0]?.permissions?.length }} permiss{{
            item.UserStore[0]?.permissions?.length == 1 ? "ão" : "ões"
          }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="!item.UserStore[0]?.isOwner"
          small
          class="mr-2"
          @click="editMember(item)"
          :disabled="!!loadingDelete"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          v-if="!item.UserStore[0]?.isOwner"
          small
          class="mr-2"
          @click="deleteMember(item)"
          color="red"
          :loading="loadingDelete == item.id"
          :disabled="!!loadingDelete"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <MemberModal ref="modal" @success="getMembers" />
  </div>
</template>

<script>
import STORE from "@/services/store";
import MemberModal from "../MemberModal.vue";

export default {
  data: () => ({
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Telefone", value: "phone" },
      { text: "Permissão", value: "permission" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    loading: false,
    loadingDelete: null,
    search: "",
    members: [],
  }),
  methods: {
    addMember() {
      this.$refs.modal.open();
    },
    editMember(member) {
      this.$refs.modal.open(member);
    },
    async getMembers() {
      this.loading = true;
      const { users } = await STORE.user.list(this.$route.params.id);
      this.members = users.map((user) => {
        return {
          ...user,
          UserStore: user.UserStore.map((us) => {
            var permissions = [];

            for (let i = 1; i < us.permissions; i *= 2) {
              if ((us.permissions & i) === i) permissions.push(i);
            }

            return { ...us, permissions };
          }),
        };
      });
      this.loading = false;
    },
    async deleteMember(member) {
      try {
        this.loadingDelete = member.id;
        const confirm = window.confirm(
          `Deseja remover ${member.name} dos colaboradores?`
        );
        await STORE.user.delete(this.$route.params.id, member.id);
        this.getMembers();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDelete = null;
      }
    },
  },
  computed: {},
  watch: {
    $route() {
      this.getMembers();
    },
  },
  created() {
    this.getMembers();
  },
  components: { MemberModal },
};
</script>
