<template>
  <v-container class="container">
    <div class="d-flex align-center gap-4">
      <div>
        <v-img :src="store.Brand?.logo" height="50" width="50" contain />
      </div>
      <component :is="$vuetify.breakpoint.mdAndUp ? 'h2' : 'h3'" class="mb-0">
        {{ store.Condominium.name }}
      </component>
      <v-spacer />
      <v-btn @click="config" icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn to="/app" icon>
        <v-icon>mdi-swap-horizontal</v-icon>
      </v-btn>
    </div>

    <ClientManagement />
    <Settings ref="settings" />
</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ClientManagement from "../../components/store/ClientManagement.vue";
import Settings from "../../components/store/modal/Settings.vue";

export default {
  name: "Home",
  data: () => ({}),
  methods: {
    config() {
      this.$refs.settings.open();
    },
  },
  computed: {
    ...mapGetters("store", ["storeById"]),
    store() {
      return this.storeById(this.$route.params.id);
    },
  },
  components: { ClientManagement, Settings },
};
</script>

<style lang="scss">
.cover-upload-wrapper {
  display: flex;
  align-items: flex-end;

  .cover-upload {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 0;
  }
  input {
    display: none;
  }
}
</style>
